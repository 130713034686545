<template>
    <div>
        <v-container>
            <div class="text-h5 text-sm-h5 text-md-h4 text-lg-h4 ma-3 text-xl-h4 text-center">
                Máquinas
            </div>

            <v-form :disabled="loading">
                <v-row>
                    <v-col cols="12" sm="7" md="5" lg="5" xl="3">
                        <v-text-field
                            class="mb-n7"
                            outlined
                            dense
                            text
                            v-model="filtros.MaqNom"
                            label="Nombre"
                            @change="loadData"
                        ></v-text-field>
                    </v-col>
                </v-row>
            </v-form>

            <v-btn
            to="/maquina-nueva"
            color="primary"
            class="mt-5 mb-3">
            Nueva
            </v-btn>

            <v-data-table
                :headers="headers"
                :items="dataRows"
                :options.sync="options"
                :footer-props="{itemsPerPageOptions: [15, 30, 50, 100]}"
                :server-items-length="totalDataRows"
                :loading="loading"
                @click:row="onClickRowEditarMaquina"
                style="overflow: scroll"
                mobile-breakpoint="0"
                class="elevation-1"
                dense
            >
                <!-- eslint-disable-next-line vue/valid-v-slot -->
                <template v-slot:item.MaqCtrlTtra="{ item }">
                    <v-simple-checkbox v-model="item.MaqCtrlTtra" disabled></v-simple-checkbox>
                </template>
            </v-data-table>
        </v-container>
    </div>
</template>

<script>
import axios from "axios";
import { mapState } from "vuex";
export default ({
    computed: {
        ...mapState(['empId', 'perId', 'urlRaiz'])
    },
    data () {
        return {
            loading: true,
            totalDataRows: 0,
            dataRows: [],
            options: {itemsPerPage: 15},
            headers: [
                { text: 'Nombre', width:270, sortable: false, value: 'MaqNom' },
                { text: 'Controlar temperatura', width:152, sortable: false, value: 'MaqCtrlTtra', align: 'center' },
                { text: 'Temperatura mínima', width: 145, sortable: false, value: 'MaqTtraMin', align: 'end' },
                { text: 'Temperatura máxima', width: 147, sortable: false, value: 'MaqTtraMax', align: 'end' },
                { text: 'Rango de temperatura', width: 152, sortable: false, value: 'MaqTtraRangoTxt' }
            ],
            dtOptionsLoaded: false,
            filtros: {
                MaqNom: ''
            }
        }
    },
    watch: {
      options: {
        handler () {
          // Evitamos la llamada a la API en la primera carga de las options
          if(!this.dtOptionsLoaded){
            this.dtOptionsLoaded = true;
            return;
          }
          this.loadData();
        },
        deep: true
      },
      // filtros: {
      //   handler () {
      //     this.loadData();
      //   },
      //   deep: true
      // },
    },
    mounted() {
      this.loadData();
    },
    methods: {
      getDataFromApi() {
        this.loading = true;

        var NumRegsPag = this.options.itemsPerPage;
        var NumPag = this.options.page;

        return new Promise((resolve) => {
          const controllerParameters = {
            Action: 'GET_DATA_LIST_PAGINATED',
            NumRegsPag: NumRegsPag,
            NumPag: NumPag,
            CampoBusqueda: 'MaqNom',
            ValorBuscar: this.filtros.MaqNom,
            EmpId: this.empId
          };
          const AuthToken = localStorage.getItem('token');
          axios({ method: "POST", "url": this.urlRaiz + "/api/maq", "data": JSON.stringify(controllerParameters), "headers": {"content-type": "application/json", "Authorization": AuthToken } })
          .then(result => {
            const items = result.data.EntsList;
            const total = result.data.EntsTotCount;

            setTimeout(() => {
                this.loading = false;
                resolve({
                items,
                total,
              })
            }, 1000);
          })
          .catch(error => {
            if(error != null) {
                this.loading = false;
                alert("Error al cargar las máquinas");
            }
          });
        });
      },
      loadData(){
        this.getDataFromApi()
        .then(data => {
          this.dataRows = data.items;
          this.totalDataRows = data.total;
        });
      },
      onClickRowEditarMaquina(item){
        this.$router.push('/maquina-editar/' + item.MaqId);
      }
    } 
})
</script>
